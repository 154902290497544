//API url
export const API_URL_PATH_PHYSICAL_DEVICE = '/physical-device';
export const API_URL_PATH_LIST_MQTT_CLIENT = '/mqtt-client/physicaldevice';
export const API_URL_PATH_CREATE_MQTT_CLIENT = '/mqtt-client';
export const API_URL_PATH_CREATE_TAG = '/tag';

export const API_URL_PATH_DEVICE_TYPE = '/device-type';

export const API_URL_PATH_DEVICE = '/device';
export const API_URL_PATH_BULK_DEVICE = '/device/bulk';

export const API_URL_PATH_LIST_APPLICATIONS = '/iot-application';
export const API_URL_PATH_LIST_CATALOG_ACCOUNTS = '/iot-application/accounts';
export const API_URL_PATH_LIST_SHOPPING_ACCOUNTS = '/shopping/accounts';
export const API_URL_PATH_LIST_SHOPPING_APPLICATIONS = '/shopping/iot-application';
export const API_URL_PATH_LIST_SHOPPING_USECASES = '/shopping/usecase';
export const API_URL_PATH_LIST_USECASES = '/usecase';
export const API_URL_PATH_UPLOAD_USECASE_IMAGE = '/usecase/image';
export const API_URL_PATH_UPLOAD_USECASE_CATALOG = '/usecase/catalog';
export const API_URL_PATH_LIST_ACCOUNTS = '/account-pool';

export const API_URL_PATH_LIST_DEVICE_TYPES = '/usecase/helper/devicetypes';

//App url paths
//Login
export const URL_PATH_LOGIN = 'login';

//Unauthorized
export const URL_PATH_UNAUTHORIZED = 'unauthorized';

//Physical device manager paths
export const URL_PATH_PHYSICAL_DEVICE = 'lns';
export const URL_FULL_PATH_LIST_PHYSICAL_DEVICE = '/lns/list';
export const URL_PATH_CREATE_PHYSICAL_DEVICE = 'create';
export const URL_PATH_EDIT_PHYSICAL_DEVICE = 'edit/:physicaldevice';

export const URL_PATH_CREATE_MQTT_CLIENT = 'create-mqtt/:physicaldevice';

export const URL_PATH_CREATE_TAG = 'create-tag';

//Device type manager paths
export const URL_PATH_DEVICE_TYPE = '/device-type';
export const URL_FULL_PATH_LIST_DEVICE_TYPE = '/device-type/list';
export const URL_PATH_CREATE_DEVICE_TYPE = 'create';
export const URL_PATH_EDIT_DEVICE_TYPE = 'update';
export const URL_FULL_PATH_EDIT_DEVICE_TYPE = '/device-type/update';

//Device manager paths
export const URL_PATH_DEVICE = '/device';
export const URL_FULL_PATH_LIST_DEVICE = '/device/list';
export const URL_PATH_CREATE_DEVICE = 'create';
export const URL_PATH_INSTALL_DEVICE = 'install';

//Catalog manager paths
export const URL_PATH_CATALOG_ADMIN = '/catalog/admin';
export const URL_PATH_CATALOG_MANAGER = '/catalog/admin/manager';
export const URL_PATH_CATALOG_USER = '/catalog/user';
export const URL_PATH_CATALOG_SHOPPING = '/catalog/user/shopping';

export const URL_PATH_CREATE_CATALOG_APPLICATION = '/catalog/admin/application/create';
export const URL_PATH_UPDATE_CATALOG_APPLICATION = '/catalog/admin/application/edit/:iotApplicationId';
export const URL_PATH_UPDATE_CATALOG_SHOPPING_APPLICATION = '/catalog/user/application/edit/:iotApplicationId';

export const URL_PATH_CREATE_CATALOG_USECASE = '/catalog/admin/usecase/create';
export const URL_PATH_UPDATE_CATALOG_USECASE = '/catalog/admin/usecase/edit/:usecaseId';

// Locations
export const API_URL_UM_RESOURCE_REGIONS = '/location';
export const API_URL_UM_RESOURCE_COUNTRIES = '/location/region';
export const API_URL_UM_RESOURCE_SITES = '/location/country';
export const API_URL_UM_RESOURCE_BUILDINGS = '/location/site';
export const API_URL_UM_RESOURCE_FLOORS = '/location/building';
export const API_URL_UM_RESOURCE_ROOMS = '/location/floor';
