import {
  SpaceBetween,
  Button,
  Header,
  Spinner,
  FormField,
  Input,
  Select,
  ColumnLayout,
  Form,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { catalogAPI } from 'api';
import { REGION_OPTIONS } from 'components/catalog-manager/Common';
import { usePageLayoutContext } from 'components/common/layout';
import { API_URL_PATH_LIST_SHOPPING_APPLICATIONS, URL_PATH_CATALOG_SHOPPING } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { NextButton } from '..';

const CreateApplicationShop = () => {
  const { selectedUseCase, setStepName, setActiveMainTabId, appsRefetch } = useCatalogShoppingContext();
  const [friendlyName, setFriendlyName] = useState('');
  const [description, setDescription] = useState('');
  const [iotApplicationId, setIotApplicationId] = useState('');
  const [region, setRegion] = useState<OptionDefinition | null>(null);
  const [parameters, setParameters] = useState<any>({});
  const [snowParentsCi, setSnowParentsCi] = useState('');
  const [disableFormFields, setDisableFormFields] = useState(false);

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [requiredParameters, setRequiredParameters] = useState<string[]>([]);

  const navigate = useNavigate();
  const location = useLocation();

  let params = useParams();

  const { setBreadcrumb } = usePageLayoutContext();

  useEffect(() => {
    let breadcrumbItems = [];

    if (
      (location && location.state?.action === 'edit') ||
      (location.pathname && location.pathname.indexOf('edit') > -1)
    ) {
      breadcrumbItems.push({
        text: 'Edit',
        href: '',
      });

      setIsEditFlow(true);
    } else {
      breadcrumbItems.push({
        text: 'Create',
        href: '/catalog/admin/application/create',
      });
    }

    setBreadcrumb([
      {
        text: 'Applications',
        href: '/catalog/admin/manager',
      },
      ...breadcrumbItems,
    ]);
  }, []);

  useEffect(() => {
    setRequiredParameters(selectedUseCase![0].requiredParameters);
  }, []);

  const {
    loading: applicationLoading,
    error: applicationError,
    fetchData: getApplication,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: `${API_URL_PATH_LIST_SHOPPING_APPLICATIONS}/${params?.iotApplicationId}`,
    },
    { manual: true }
  );

  //   useEffect(() => {
  //     if (isEditFlow) {
  //         getApplication()
  //             .then((application) => {
  //                 if (application?.data) {
  //                     setIotApplicationId(application.data.iotApplicationId);
  //                     setFriendlyName(application.data.friendlyName);
  //                     setDescription(application.data.description);
  //                     setSnowParentsCi(application.data.snowParentsCi);
  //                 } else {
  //                     navigate(URL_PATH_CREATE_CATALOG_APPLICATION);
  //                 }
  //             })
  //             .catch((error) => {
  //                 console.error(error);
  //             });
  //     }
  // }, [isEditFlow]);

  //   const {
  //     error: updateAppError,
  //     loading: updateAppLoading,
  //     status: updateAppStatus,
  //     fetchData: updateApp,
  // } = useFetch(
  //     {
  //         axiosInstance: catalogAPI,
  //         method: 'PATCH',
  //         url: `${API_URL_PATH_LIST_APPLICATIONS}/${iotApplicationId}`,
  //         headers: {
  //             'Content-Type': 'application/json',
  //         },
  //         data: {
  //             friendlyName,
  //             description,
  //             snowParentsCi,
  //         },
  //     },
  //     { manual: true }
  // );

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_LIST_SHOPPING_APPLICATIONS,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        usecaseId: selectedUseCase![0].usecaseId,
        friendlyName,
        description,
        iotApplicationId,
        region: region?.value,
        parameters,
        snowParentsCi,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (status === 201) {
      let stateObj = {};

      if (isEditFlow) {
        stateObj = {
          action: 'update-application',
          status: 'success',
          message: `Successfully updated application ${iotApplicationId}`,
          tab: 'applications',
        };
      } else {
        stateObj = {
          action: 'create-application',
          status: 'success',
          message: `Successfully created application ${iotApplicationId}`,
          tab: 'applications',
        };
      }
      appsRefetch();
      setActiveMainTabId('applications');
    } else if (status > 201) {
      setDisableFormFields(false);
    }
  }, [status]);

  const handleRequiredParameters = (paramValue: string, param: string) => {
    let paramObj = { ...parameters };
    paramObj[param] = paramValue;
    setParameters(paramObj);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);

    if (isEditFlow) {
      // updateApp();
    } else {
      fetchData();
    }
  };

  const handleNextStep = () => {
    return setStepName('buy');
  };

  const handlePrevStep = () => {
    return setStepName('details');
  };

  return (
    <>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: '20px' }}>3. Buy Application</div>
        <div>
          <NextButton handleClick={handlePrevStep} text="Back" />
          {/* &nbsp;&nbsp;
                    <NextButton handleClick={handleNextStep} text='Buy' /> */}
        </div>
      </div>
      {/* <br /> */}
      <form onSubmit={handleSubmit}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button formAction="none" variant="link" onClick={() => setActiveMainTabId('applications')}>
                Cancel
              </Button>
              <Button variant="primary" loading={loading}>
                Submit
              </Button>
            </SpaceBetween>
          }
          header={
            <Header variant="h2" description="">
              {/* {isEditFlow ? 'Update' : 'Create'} an IoT
                            Application */}
            </Header>
          }
          errorText={error || applicationError}
        >
          <SpaceBetween direction="vertical" size="l">
            {applicationLoading ? (
              <Spinner />
            ) : (
              <>
                <FormField label="IoT Application ID">
                  <Input
                    disabled={disableFormFields || isEditFlow}
                    value={iotApplicationId}
                    onChange={(event) => setIotApplicationId(event.detail.value)}
                  />
                </FormField>
                <FormField label="Friendly name">
                  <Input
                    disabled={disableFormFields}
                    value={friendlyName}
                    onChange={(event) => setFriendlyName(event.detail.value)}
                  />
                </FormField>
                <FormField label="Description">
                  <Input
                    disabled={disableFormFields}
                    value={description}
                    onChange={(event) => setDescription(event.detail.value)}
                  />
                </FormField>

                <FormField label="SNOW Parent CI">
                  <Input
                    disabled={disableFormFields}
                    value={snowParentsCi}
                    onChange={(event) => setSnowParentsCi(event.detail.value)}
                  />
                </FormField>
              </>
            )}
            {!isEditFlow && (
              <>
                <FormField label="Usecase ID">
                  <Input disabled={true} value={selectedUseCase![0].usecaseId} />
                </FormField>

                {requiredParameters.length > 0 && (
                  <FormField label="Parameters" description="Please input the values for each parameter">
                    <ColumnLayout columns={requiredParameters?.length > 2 ? 3 : 2}>
                      {requiredParameters.map((param) => (
                        <div key={param}>
                          <span>{param}</span>
                          <Input
                            disabled={disableFormFields}
                            value={parameters[param]}
                            onChange={(event) => {
                              handleRequiredParameters(event.detail.value, param);
                            }}
                          />
                        </div>
                      ))}
                    </ColumnLayout>
                  </FormField>
                )}

                <FormField label="Region">
                  <Select
                    selectedOption={region}
                    onChange={({ detail }) => {
                      setRegion(detail.selectedOption);
                    }}
                    options={REGION_OPTIONS}
                    placeholder="Choose an option"
                    disabled={disableFormFields}
                    empty="No regions"
                  />
                </FormField>
              </>
            )}
          </SpaceBetween>
        </Form>
      </form>
    </>
  );
};

export default CreateApplicationShop;
