import { Tabs } from '@cloudscape-design/components';
import AwsAccounts from 'components/catalog-manager/AccountsRegistry/AccountsRegistryTable';
import ApplicationsTable from 'components/catalog-manager/Applications/ApplicationsTable';
import UseCase from 'components/catalog-manager/UseCase/UseCaseTable';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';

const CatalogMainTabs = () => {
  const { activeMainTabId, setActiveMainTabId } = useCatalogManagerContext();
  return (
    <Tabs
      tabs={[
        {
          label: 'Applications',
          id: 'applications',
          content: <ApplicationsTable type="manager" />,
        },
        {
          label: 'Use Case',
          id: 'use-case',
          content: <UseCase />,
        },
        {
          label: 'AWS Accounts',
          id: 'aws-accounts',
          content: <AwsAccounts />,
        },
      ]}
      className="tabs-container"
      variant="container"
      activeTabId={activeMainTabId}
      onChange={({ detail }) => setActiveMainTabId(detail.activeTabId)}
    />
  );
};

export default CatalogMainTabs;
