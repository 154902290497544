import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';
import { NextButton } from '..';

const UsecaseDetails = () => {
  const defaultUsecasePDF = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf#toolbar=0';

  const { selectedUseCase, setStepName } = useCatalogShoppingContext();

  const handleNextStep = () => {
    return setStepName('buy');
  };

  const handlePrevStep = () => {
    return setStepName('choose');
  };

  return (
    <>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: '20px' }}>2. Usecase Details</div>
        <div>
          <NextButton handleClick={handlePrevStep} text="Back" />
          &nbsp;&nbsp;
          <NextButton handleClick={handleNextStep} text="Next" />
        </div>
      </div>
      <br />

      <iframe
        id="usecase-details-iframe"
        title="test"
        src={selectedUseCase![0]?.catalogUrl ? `${selectedUseCase![0]?.catalogUrl}#toolbar=0` : defaultUsecasePDF}
        width="100%"
        height="900"
      ></iframe>
    </>
  );
};

export default UsecaseDetails;
