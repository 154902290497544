import { useState, useRef } from 'react';
import { Modal, Box, SpaceBetween, Button, FormField, Icon } from '@cloudscape-design/components';
import Papa from 'papaparse';
import { CreateDeviceProps } from 'types/custom';

const CreateBulkDevices = ({
  onDismiss,
  visible,
  setBulkDevices,
  createBulkDevice,
  createBulkDeviceLoading,
}: {
  onDismiss: () => void;
  visible: boolean;
  setBulkDevices: (state: CreateDeviceProps[]) => void;
  createBulkDevice: () => Promise<any>;
  createBulkDeviceLoading: boolean;
}) => {
  const [csvFile, setCsvFile] = useState<any>();
  const inputCSVRef = useRef<HTMLInputElement | null>(null);

  const handleCSVSelect = (event: any) => {
    event.preventDefault();
    inputCSVRef.current?.click();
  };

  const handleCSVUpload = (event: any) => {
    if (event.target?.files) {
      setCsvFile(event.target.files![0]);

      Papa.parse(event.target.files![0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results: any) {
          setBulkDevices(results.data);
        },
      });
    }
  };

  const handleCreateBulkDevice = (event: any) => {
    createBulkDevice();
  };

  return (
    <Modal
      onDismiss={onDismiss}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDismiss}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCreateBulkDevice} loading={createBulkDeviceLoading}>
              Create
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Bulk device creation"
    >
      <FormField
        label="Upload CSV File"
        description="The CSV file should have the following columns: lookupId, invoiceId, physicalDeviceId, friendlyName, description, deviceEUI, appEUI, deviceTypeId, deviceGroupId & appKey"
      >
        <br></br>
        <Button onClick={handleCSVSelect}>
          <Icon name="upload" />
          &nbsp;&nbsp;Choose file
        </Button>
        <br></br>
        <br></br>
        {csvFile && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Icon name="status-positive" variant="success" />
            <div>
              &nbsp;
              <span>{csvFile.name}</span>
              <div>{csvFile.size} bytes</div>
              {/* <div>{(csvFile.size * 0.000001).toFixed(2)} MB</div> */}
            </div>
          </div>
        )}
        <input type="file" ref={inputCSVRef} accept=".csv" onChange={handleCSVUpload} style={{ display: 'none' }} />
      </FormField>
    </Modal>
  );
};

export default CreateBulkDevices;
