import { ColumnLayout, SpaceBetween, Box } from '@cloudscape-design/components';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';

const UseCaseDetails = () => {
  const { selectedUseCase } = useCatalogManagerContext();

  return (
    <ColumnLayout columns={3} variant="text-grid">
      {selectedUseCase ? (
        <>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Friendly Name</Box>
              <div>{selectedUseCase![0]?.friendlyName}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Description</Box>
              <div>{selectedUseCase![0]?.description}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Installation Instruction</Box>
              <div>{selectedUseCase![0]?.installationInstruction}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Updated At</Box>
              <div>{selectedUseCase![0]?.updatedAt}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Account ID</Box>
              <div>{selectedUseCase![0]?.approximateCostMonthly?.toString()}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Contact Info</Box>
              <div>{selectedUseCase![0]?.contactInfo}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Repository</Box>
              <div>{selectedUseCase![0]?.repo}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Required Parameters</Box>
              <div>{selectedUseCase![0]?.requiredParameters?.join(', ') || '-'}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">IoT Applications</Box>
              <div>{selectedUseCase![0]?.iotApplications?.join(', ') || '-'}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Region Availability</Box>
              <div>{selectedUseCase![0]?.regionAvailability?.join(', ') || '-'}</div>
            </div>
          </SpaceBetween>
        </>
      ) : (
        <i>No data to display</i>
      )}
    </ColumnLayout>
  );
};

export default UseCaseDetails;
