import React, { createContext, useContext, useEffect, useState } from 'react';
import { ColumnLayout, Box } from '@cloudscape-design/components';

import Spinner from 'components/spinner/Spinner';
import DeviceTypesTable from 'components/device-type-manager/DeviceTypesTable';
import DeviceTypesTabs from 'components/device-type-manager/DeviceTypesTabs';
import { API_URL_PATH_DEVICE_TYPE } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { DeviceTypesListProps, DeviceTypesResponse } from 'types/custom';
import { usePageLayoutContext } from 'components/common/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { platformCoreAPI } from 'api';
import { extractFilteringAttributes } from 'utils';

const DeviceTypesContext = createContext({
  allItems: [] as any[],
  loading: false,
  error: '',
  setSelectedDeviceType: (state: DeviceTypesListProps[]) => {},
  selectedDeviceType: [] as DeviceTypesListProps[] | undefined,
  refetch: () => {},
});

export const useDeviceTypesContext = () => useContext(DeviceTypesContext);

const ListDeviceTypesPage = () => {
  const [selectedDeviceType, setSelectedDeviceType] = useState<DeviceTypesListProps[]>([]);

  const [allItems, setAllItems] = useState<DeviceTypesListProps[]>([]);

  const { setBreadcrumb, setNotification } = usePageLayoutContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setBreadcrumb([]);
    if (location?.state?.action) {
      setNotification([
        {
          type: 'success',
          content: location.state.message,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: `flash-${location.state.action}`,
        },
      ]);
    }

    navigate(location.pathname, {});
  }, []);

  const { response, loading, status, error, refetch } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: API_URL_PATH_DEVICE_TYPE,
    },
    { manual: false }
  );

  useEffect(() => {
    if (status === 200) {
      setAllItems(extractFilteringAttributes(response.results));
    }
  }, [status, response]);

  return (
    <DeviceTypesContext.Provider
      value={{
        loading,
        allItems,
        error,
        setSelectedDeviceType,
        selectedDeviceType,
        refetch,
      }}
    >
      <ColumnLayout variant="text-grid">
        {loading && (
          <>
            <br></br>
            <Spinner />
          </>
        )}
        {response && <DeviceTypesTable />}
      </ColumnLayout>

      {response && selectedDeviceType![0]?.name !== undefined && (
        <>
          <Box margin={{ top: 'xxxl' }}></Box>
          <ColumnLayout variant="text-grid">
            <DeviceTypesTabs />
          </ColumnLayout>
        </>
      )}
    </DeviceTypesContext.Provider>
  );
};

export default ListDeviceTypesPage;
