import { Tabs } from '@cloudscape-design/components';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import AccountDetails from './AccountDetails';
import ApplicationDetails from './ApplicationDetails';
import UseCaseDetails from './UseCaseDetails';

const CatalogDetailsTabs = () => {
  const { activeMainTabId } = useCatalogManagerContext();

  const CatalogDetails = () => {
    return activeMainTabId === 'applications' ? (
      <ApplicationDetails type="manager" />
    ) : activeMainTabId === 'use-case' ? (
      <UseCaseDetails />
    ) : (
      <AccountDetails />
    );
  };

  return (
    <Tabs
      tabs={[
        {
          label: 'Details',
          id: 'details',
          content: <CatalogDetails />,
        },
      ]}
      className="tabs-container"
      variant="container"
    />
  );
};

export default CatalogDetailsTabs;
