import { Container } from '@cloudscape-design/components';
import CreateUsecase from 'components/catalog-manager/UseCase/CreateUsecase';

const UpdateUsecasePage = () => {
  return (
    <Container className="iot-container">
      <CreateUsecase />
    </Container>
  );
};

export default UpdateUsecasePage;
