import { SelectProps } from "@cloudscape-design/components";

export const selectFewerProps = (data: any) => {
  const { name: label, id: { id: value } = { id: {} } } = data;
  return { label, value };
};

export const selectUseCaseProps = (data: any) => {
  const { friendlyName: label, usecaseId: value } = data;
  return { label, value };
};

export const dropdownFromProps = (data: any) => {
  const {
    attributes: { friendlyName: label },
    name: value,
  } = data;
  return { label, value };
};

export const selectAccountsProps = (data: any) => {
  const { accountId: label, accountId: value } = data;
  return { label, value };
};

export const getMatchesCountText = (count: number | undefined) => {
  return `${count} ${count === 1 ? 'match' : 'matches'}`;
};

export const checkKeyDown = (e: any) => {
  if (e.code === 'Enter') e.preventDefault();
};

export const buildArrayForPayload = (arrObj: any) => {
  return arrObj.map((item: { label: string; value: string }) => item.value || item.label);
};

export const buildExpectedOutput = (arrObj: any) => {
  const initialObj = {};
  return arrObj.reduce((obj: any, item: { label: string; value: string }) => {
    return {
      ...obj,
      [item['label']]: item.value?.replace(/\s*,\s*/g, ',')?.split(','),
    };
  }, initialObj);
};

export const extractFilteringAttributes = (list: any[]) => {
  // This is a workaround for Cloudscape filtering search to work also with the friendly name
  return list.map((item: any) => {
    item.friendlyName = item?.attributes?.friendlyName;
    return item;
  });
};

export type LocationType = 'region' | 'country' | 'site' | 'building' | 'floor' | 'room';

export const selectLocationProps = (data: any, type: LocationType): SelectProps.Option => {
  const code = data[type + 'no'] || data[type + 'code']
  return {
      label: String(data[type] || data[type + 'name'] || data[type + 'id']),
      value: String(data[type + 'id']),
      labelTag: code ? `${code} [${data[type + 'id']}]` : `${data[type + 'id']}`,
      description: `Created at ${new Date(data.datecreated).toLocaleString().replace(',', '')}${data.datemodified ? '. Modified at ' + new Date(data.datemodified).toLocaleString().replace(',', '') : ''}`,
  };
};