import React from 'react';
import ReactDOM from 'react-dom/client';

import './fonts/bi-sans-next/BISansNEXT-Regular.otf';
import './index.css';
import './runtimeConfig';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'setup/app-context-manager/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
