import { useNavigate } from 'react-router';
import { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      console.log('interceptor error', error);
    } else {
      return Promise.reject(error);
    }
  }
);

const useFetch = (config: any, { manual }: any) => {
  const [response, setResponse] = useState<any>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<number>(0);
  const [reload, setReload] = useState(0);
  let manualReloads = useRef(manual);
  let controller = useRef(new AbortController());

  const { axiosInstance, method, url, param = '', params = null, data } = config;
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (controller.current.signal.aborted) controller.current = new AbortController();
    setStatus(0);
    if (/[^a-zA-Z0-9_-]/.test(param)) throw new Error('Invalid parameter for URL');
    if (params && params.some((x: string) => /[^a-zA-Z0-9_-]/.test(x))) throw new Error('Invalid parameter for URL');

    try {
      const isFormData = data instanceof FormData;

      if (method.toLowerCase() === 'post') {
        axios.defaults.data = data;
      }

      const result = await axiosInstance.request({
        method: method.toLowerCase(),
        url:
          (url.endsWith('/') ? url.slice(0, -1) : url) +
          (param ? `/${param}` : '') +
          (params && params.length ? `/${params.join('/')}` : ''),
        data: ['GET', 'DELETE'].includes(method) ? undefined : isFormData ? data : JSON.stringify(data),
        signal: controller.current?.signal,
        headers: {
          'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
      });

      setResponse(result?.data);
      setStatus(result?.status);
      setError('');

      return result;
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        setError(
          (Array.isArray(error.response.data.message)
            ? error.response.data.message.join('. ')
            : error.response.data.message) || `Unexpected error: ${error.response?.data || '-'}`
        );
        setStatus(error.response?.status);
        if (error.response?.status === 403) {
          navigate('/unauthorized');
        }
      } else {
        setError(error.message);
        setStatus(502);
      }
    } finally {
      setLoading(false);
    }
  }, [method, param, params, url, data, axiosInstance, navigate]);

  const refetch = useCallback(
    (newManual: boolean | null = null) => {
      setReload((prev: number) => prev + 1);
      if (newManual !== null) manualReloads.current = newManual;
      fetchData();
    },
    [fetchData]
  );

  useEffect(() => {
    if (!manualReloads.current) fetchData();

    return () => controller.current?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, controller]);

  return { response, error, loading, status, fetchData, refetch };
};

export default useFetch;
