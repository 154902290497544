import { Box, ColumnLayout } from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import CatalogDetailsTabs from 'components/catalog-shopping/CatalogDetailsTabs';
import CatalogMainTabs from 'components/catalog-shopping/CatalogMainTabs';
import { usePageLayoutContext } from 'components/common/layout';
import Spinner from 'components/spinner/Spinner';
import { API_URL_PATH_LIST_SHOPPING_APPLICATIONS, API_URL_PATH_LIST_SHOPPING_USECASES } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CatalogApplicationProps, CatalogUseCaseProps } from 'types/custom';

const CatalogShoppingContext = createContext({
  allApplications: [] as CatalogApplicationProps[],
  allUseCases: [] as CatalogUseCaseProps[],
  appsError: '',
  useCaseError: '',
  appsLoading: false,
  useCaseLoading: false,
  selectedApplication: [] as CatalogApplicationProps[] | undefined,
  selectedUseCase: [] as CatalogUseCaseProps[] | undefined,
  setSelectedApplication: (state: CatalogApplicationProps[]) => {},
  setSelectedUseCase: (state: CatalogUseCaseProps[]) => {},
  appsRefetch: () => {},
  activeMainTabId: '',
  setActiveMainTabId: (state: string) => {},
  stepName: '',
  setStepName: (state: string) => {},
});

export const useCatalogShoppingContext = () => useContext(CatalogShoppingContext);

const CatalogShoppingPage = () => {
  const [allApplications, setAllApplications] = useState<CatalogApplicationProps[]>([]);
  const [allUseCases, setAllUseCases] = useState<CatalogUseCaseProps[]>([]);

  const [selectedApplication, setSelectedApplication] = useState<CatalogApplicationProps[]>();
  const [selectedUseCase, setSelectedUseCase] = useState<CatalogUseCaseProps[]>();

  const [activeMainTabId, setActiveMainTabId] = useState('applications');
  const [stepName, setStepName] = useState<any>('choose');

  const { setBreadcrumb, setNotification } = usePageLayoutContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setBreadcrumb([]);
    const locationState = location?.state;
    if (locationState?.action) {
      setNotification([
        {
          type: 'success',
          content: locationState.message,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: `flash-${locationState.action}`,
        },
      ]);

      if (locationState.tab) {
        setActiveMainTabId(locationState.tab);
      }
    }

    navigate(location.pathname, {});
  }, []);

  const {
    response: appsResponse,
    loading: appsLoading,
    error: appsError,
    refetch: appsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_SHOPPING_APPLICATIONS,
    },
    { manual: false }
  );

  const {
    response: useCaseResponse,
    loading: useCaseLoading,
    error: useCaseError,
    refetch: useCaseRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_SHOPPING_USECASES,
    },
    { manual: false }
  );

  useEffect(() => {
    if (appsResponse) {
      setAllApplications(appsResponse?.items);
    }
  }, [appsResponse]);

  useEffect(() => {
    if (useCaseResponse) {
      setAllUseCases(useCaseResponse?.items);
    }
  }, [useCaseResponse]);

  return (
    <CatalogShoppingContext.Provider
      value={{
        allApplications,
        allUseCases,
        appsError,
        useCaseError,
        appsLoading,
        useCaseLoading,
        selectedApplication,
        setSelectedApplication,
        selectedUseCase,
        setSelectedUseCase,
        appsRefetch,
        activeMainTabId,
        setActiveMainTabId,
        stepName,
        setStepName,
      }}
    >
      <ColumnLayout variant="text-grid">
        {appsLoading && (
          <>
            <br></br>
            <Spinner />
          </>
        )}
        {appsResponse && <CatalogMainTabs />}
      </ColumnLayout>

      {appsResponse && activeMainTabId === 'applications' && (
        <>
          <Box margin={{ top: 'xxxl' }}></Box>
          <ColumnLayout variant="text-grid">
            <CatalogDetailsTabs />
          </ColumnLayout>
        </>
      )}
    </CatalogShoppingContext.Provider>
  );
};

export default CatalogShoppingPage;
