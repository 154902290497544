import {
  SpaceBetween,
  Button,
  Header,
  FormField,
  Input,
  Form,
  Multiselect,
  TokenGroup,
  AttributeEditor,
  Icon,
} from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import axios from 'axios';
import { usePageLayoutContext } from 'components/common/layout';
import Spinner from 'components/spinner/Spinner';
import {
  API_URL_PATH_LIST_DEVICE_TYPES,
  API_URL_PATH_LIST_USECASES,
  API_URL_PATH_UPLOAD_USECASE_CATALOG,
  API_URL_PATH_UPLOAD_USECASE_IMAGE,
  URL_PATH_CATALOG_MANAGER,
  URL_PATH_CREATE_CATALOG_USECASE,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { buildArrayForPayload, buildExpectedOutput, checkKeyDown, dropdownFromProps } from 'utils';
import { ACCESS_MAPS, REGION_OPTIONS } from '../../Common';

const Control = React.memo(
  ({ value, index, setOutputItems, prop }: { value: any; index: any; setOutputItems: any; prop: string }) => {
    return (
      <Input
        value={value}
        onChange={({ detail }) => {
          setOutputItems((items: any) => {
            const updatedItems = [...items];
            updatedItems[index] = {
              ...updatedItems[index],
              [prop]: detail.value,
            };
            return updatedItems;
          });
        }}
      />
    );
  }
);

const CreateUsecase = () => {
  const [usecaseId, setUsecaseId] = useState<string>('');
  const [friendlyName, setFriendlyName] = useState('');
  const [description, setDescription] = useState('');
  const [accessMap, setAccessMap] = useState<any>([]);
  const [region, setRegion] = useState<any>([]);
  const [installationInstruction, setInstallationInstruction] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [approximateCostMonthly, setApproximateCostMonthly] = useState('');
  const [repo, setRepo] = useState('');
  const [homepageUrl, setHomepageUrl] = useState('');
  const [supportedDeviceTypes, setSupportedDeviceTypes] = useState<any>([]);
  const [outputItems, setOutputItems] = useState<any>([{}]);

  const [enterParameters, setEnterParameters] = useState('');
  const [requiredParameters, setRequiredParameters] = useState<any>([]);
  const [allDeviceTypes, setAllDeviceTypes] = useState([]);

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [disableFormFields, setDisableFormFields] = useState(false);

  const inputImageRef = useRef<HTMLInputElement | null>(null);
  const inputPdfRef = useRef<HTMLInputElement | null>(null);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [selectedPdf, setSelectedPdf] = useState<any>();

  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();

  const { setBreadcrumb } = usePageLayoutContext();
  const ImgFormData = new FormData();
  const CatalogFormData = new FormData();

  useEffect(() => {
    let breadcrumbItems = [];

    if (
      (location && location.state?.action === 'edit') ||
      (location.pathname && location.pathname.indexOf('edit') > -1)
    ) {
      breadcrumbItems.push({
        text: 'Edit',
        href: '',
      });

      setIsEditFlow(true);
    } else {
      breadcrumbItems.push({
        text: 'Create',
        href: '/catalog/admin/usecase/create',
      });
    }

    setBreadcrumb([
      {
        text: 'Usecase',
        href: '/catalog/admin/manager',
      },
      ...breadcrumbItems,
    ]);
  }, []);

  const {
    loading: getUseCaseLoading,
    error: getUseCaseError,
    fetchData: getUseCase,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: `${API_URL_PATH_LIST_USECASES}/${params?.usecaseId}`,
    },
    { manual: true }
  );

  const buildLabelValueArray = (arr: []) => {
    return arr.map((item: string) => {
      return {
        label: item,
        value: item,
      };
    });
  };

  useEffect(() => {
    if (isEditFlow) {
      getUseCase()
        .then((usecase) => {
          if (usecase?.data) {
            const {
              usecaseId,
              friendlyName,
              description,
              installationInstruction,
              contactInfo,
              approximateCostMonthly,
              repo,
              homepageUrl,
              regionAvailability,
              requiredParameters,
              supportedDeviceTypes,
              accessMap,
            } = usecase?.data;
            setUsecaseId(usecaseId);
            setFriendlyName(friendlyName);
            setDescription(description);
            setInstallationInstruction(installationInstruction);
            setContactInfo(contactInfo);
            setApproximateCostMonthly(approximateCostMonthly);
            setRepo(repo);
            setHomepageUrl(homepageUrl);

            setRegion(() => {
              return buildLabelValueArray(regionAvailability);
            });

            setAccessMap(() => {
              return buildLabelValueArray(accessMap);
            });

            setSupportedDeviceTypes(() => {
              return buildLabelValueArray(supportedDeviceTypes);
            });

            setRequiredParameters(() => {
              return buildLabelValueArray(requiredParameters);
            });
          } else {
            navigate(URL_PATH_CREATE_CATALOG_USECASE);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isEditFlow]);

  const {
    response: deviceTypesResponse,
    loading: deviceTypesLoading,
    error: deviceTypesError,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_DEVICE_TYPES,
    },
    { manual: false }
  );

  useEffect(() => {
    if (deviceTypesResponse) {
      const deviceTypesOptions = deviceTypesResponse.map(dropdownFromProps);
      setAllDeviceTypes(deviceTypesOptions);
    }
  }, [deviceTypesResponse]);

  const {
    error: updateUsecaseError,
    loading: updateUsecaseLoading,
    status: updateUsecaseStatus,
    fetchData: updateUsecase,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'PATCH',
      url: `${API_URL_PATH_LIST_USECASES}/${usecaseId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        usecaseId,
        friendlyName,
        description,
        installationInstruction,
        repo,
        homepageUrl,
        contactInfo,
        approximateCostMonthly: parseFloat(approximateCostMonthly),
        regionAvailability: buildArrayForPayload(region),
        accessMap: buildArrayForPayload(accessMap),
        supportedDeviceTypes: buildArrayForPayload(supportedDeviceTypes),
        requiredParameters: buildArrayForPayload(requiredParameters),
      },
    },
    { manual: true }
  );

  const {
    error: createUsecaseError,
    loading: createUsecaseLoading,
    status: createUsecaseStatus,
    fetchData: createUsecase,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_LIST_USECASES,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        usecaseId,
        friendlyName,
        description,
        installationInstruction,
        repo,
        homepageUrl,
        contactInfo,
        approximateCostMonthly: parseFloat(approximateCostMonthly),
        regionAvailability: buildArrayForPayload(region),
        accessMap: buildArrayForPayload(accessMap),
        supportedDeviceTypes: buildArrayForPayload(supportedDeviceTypes),
        requiredParameters: buildArrayForPayload(requiredParameters),
        expectedOutputs: buildExpectedOutput(outputItems),
      },
    },
    { manual: true }
  );

  const {
    error: uploadUsecaseImageError,
    fetchData: uploadUsecaseImage,
    loading: uploadUsecaseImageLoading,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_UPLOAD_USECASE_IMAGE,
      data: ImgFormData,
    },
    { manual: true }
  );

  const {
    error: uploadUsecasePdfError,
    fetchData: uploadUsecasePdf,
    loading: uploadUsecasePdfLoading,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_UPLOAD_USECASE_CATALOG,
      data: CatalogFormData,
    },
    { manual: true }
  );

  useEffect(() => {
    if (createUsecaseStatus === 201 || updateUsecaseStatus === 200) {
      let stateObj = {};
      let axiosArr = [];

      if (selectedImage) {
        ImgFormData.append('usecaseId', usecaseId);
        ImgFormData.append('image', selectedImage);
        axiosArr.push(uploadUsecaseImage());
      }

      if (selectedPdf) {
        CatalogFormData.append('usecaseId', usecaseId);
        CatalogFormData.append('catalog', selectedPdf);
        axiosArr.push(uploadUsecasePdf());
      }

      if (isEditFlow) {
        stateObj = {
          action: 'update-usecase',
          status: 'success',
          message: `Successfully updated usecase ${usecaseId}`,
          tab: 'use-case',
        };
      } else {
        stateObj = {
          action: 'create-usecase',
          status: 'success',
          message: `Successfully created usecase ${usecaseId}`,
          tab: 'use-case',
        };
      }

      if (axiosArr.length > 0) {
        axios
          .all(axiosArr)
          .then(
            axios.spread((...response) => {
              if (response.length > 0 && response[0] !== undefined) {
                navigate(URL_PATH_CATALOG_MANAGER, {
                  state: stateObj,
                });
              }
            })
          )
          .catch((err) => {
            console.error(err);
            setDisableFormFields(false);
          });
      } else {
        navigate(URL_PATH_CATALOG_MANAGER, {
          state: stateObj,
        });
      }
    } else if (createUsecaseStatus > 201) {
      setDisableFormFields(false);
    }
  }, [createUsecaseStatus, updateUsecaseStatus]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);

    if (isEditFlow) {
      updateUsecase();
    } else {
      createUsecase();
    }
  };

  const handleImageSelect = (event: any) => {
    event.preventDefault();
    inputImageRef.current?.click();
  };

  const handlePdfSelect = (event: any) => {
    event.preventDefault();
    inputPdfRef.current?.click();
  };

  // useEffect(() => {
  //     ImgFormData.append('usecaseId', 'IOT_DEMO_USECASE_05');
  //     ImgFormData.append('image', selectedImage);
  //     uploadUsecaseImage();
  // }, [selectedImage]);

  // useEffect(() => {
  //     CatalogFormData.append('usecaseId', usecaseId);
  //     CatalogFormData.append('catalog', selectedPdf);
  //     // uploadUsecasePdf();
  // }, [selectedPdf]);

  return (
    <form onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate(URL_PATH_CATALOG_MANAGER, {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={createUsecaseLoading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            {isEditFlow ? 'Update' : 'Create'} a Usecase
          </Header>
        }
        errorText={createUsecaseError || getUseCaseError}
      >
        <SpaceBetween direction="vertical" size="l">
          {getUseCaseLoading ? (
            <Spinner />
          ) : (
            <>
              <FormField label="Usecase ID">
                <Input
                  disabled={disableFormFields || isEditFlow}
                  value={usecaseId}
                  onChange={(event) => setUsecaseId(event.detail.value)}
                />
              </FormField>
              <FormField label="Friendly name">
                <Input
                  disabled={disableFormFields}
                  value={friendlyName}
                  onChange={(event) => setFriendlyName(event.detail.value)}
                />
              </FormField>
              <FormField label="Description">
                <Input
                  disabled={disableFormFields}
                  value={description}
                  onChange={(event) => setDescription(event.detail.value)}
                />
              </FormField>

              <FormField
                label={
                  <span>
                    Installation Instruction <i>- optional</i>{' '}
                  </span>
                }
              >
                <Input
                  disabled={disableFormFields}
                  value={installationInstruction}
                  onChange={(event) => setInstallationInstruction(event.detail.value)}
                />
              </FormField>

              <FormField label="Repository">
                <Input disabled={disableFormFields} value={repo} onChange={(event) => setRepo(event.detail.value)} />
              </FormField>

              <FormField
                label={
                  <span>
                    Home Page URL <i>- optional</i>{' '}
                  </span>
                }
              >
                <Input
                  disabled={disableFormFields}
                  value={homepageUrl}
                  onChange={(event) => setHomepageUrl(event.detail.value)}
                />
              </FormField>

              <FormField label="Region">
                <Multiselect
                  selectedOptions={region}
                  onChange={({ detail }) => {
                    setRegion(detail.selectedOptions);
                  }}
                  options={REGION_OPTIONS}
                  placeholder="Choose regions"
                  disabled={disableFormFields}
                />
              </FormField>

              <FormField label="Access Maps">
                <Multiselect
                  selectedOptions={accessMap}
                  onChange={({ detail }) => {
                    setAccessMap(detail.selectedOptions);
                  }}
                  options={ACCESS_MAPS}
                  placeholder="Choose access maps"
                  disabled={disableFormFields}
                />
              </FormField>

              <FormField label="Supported Device Types">
                <Multiselect
                  selectedOptions={supportedDeviceTypes}
                  onChange={({ detail }) => {
                    setSupportedDeviceTypes(detail.selectedOptions);
                  }}
                  options={allDeviceTypes}
                  placeholder="Choose device types"
                  disabled={disableFormFields}
                  loadingText="Loading device types"
                  statusType={deviceTypesLoading ? 'loading' : 'finished'}
                  errorText={deviceTypesError}
                />
              </FormField>

              <FormField
                label={
                  <span>
                    Contact Info <i>- optional</i>{' '}
                  </span>
                }
              >
                <Input
                  disabled={disableFormFields}
                  value={contactInfo}
                  onChange={(event) => setContactInfo(event.detail.value)}
                />
              </FormField>

              <FormField
                label={
                  <span>
                    Approximate Monthly Cost <i>- optional</i>{' '}
                  </span>
                }
              >
                <Input
                  disabled={disableFormFields}
                  value={approximateCostMonthly}
                  onChange={(event) => setApproximateCostMonthly(event.detail.value)}
                />
              </FormField>

              <FormField label="Required Parameters" description="Enter each required parameter and press ENTER key">
                <Input
                  disabled={disableFormFields}
                  value={enterParameters}
                  onKeyDown={(event) => {
                    if (event.detail.key === 'Enter') {
                      setRequiredParameters([...requiredParameters, { label: enterParameters }]);
                      setEnterParameters('');
                    }
                  }}
                  onChange={(event) => setEnterParameters(event.detail.value)}
                />
                <TokenGroup
                  onDismiss={({ detail: { itemIndex } }) => {
                    setRequiredParameters([
                      ...requiredParameters.slice(0, itemIndex),
                      ...requiredParameters.slice(itemIndex + 1),
                    ]);
                  }}
                  items={requiredParameters}
                />
              </FormField>

              {!isEditFlow && (
                <>
                  <FormField
                    label="Expected Outputs"
                    description="Enter stack name and separate each output value with a comma"
                  >
                    <AttributeEditor
                      onAddButtonClick={() => setOutputItems([...outputItems, {}])}
                      onRemoveButtonClick={({ detail: { itemIndex } }) => {
                        const tmpItems = [...outputItems];
                        tmpItems.splice(itemIndex, 1);
                        setOutputItems(tmpItems);
                      }}
                      items={outputItems}
                      addButtonText="Add new stack"
                      definition={[
                        {
                          label: 'Stack Name',
                          control: (item: any, itemIndex) => (
                            <Control
                              prop="label"
                              value={item.label}
                              index={itemIndex}
                              setOutputItems={setOutputItems}
                            />
                          ),
                        },
                        {
                          label: 'Output Values',
                          control: (item, itemIndex) => (
                            <Control
                              prop="value"
                              value={item.value}
                              index={itemIndex}
                              setOutputItems={setOutputItems}
                            />
                          ),
                        },
                      ]}
                      removeButtonText="Remove"
                      empty="No items associated with the resource."
                    />
                  </FormField>

                  <FormField
                    label="Upload Image"
                    errorText={uploadUsecaseImageError}
                    description="Image size should be less than 1MB"
                  >
                    <Button onClick={handleImageSelect}>
                      <Icon name="upload" />
                      &nbsp;&nbsp;Choose file
                    </Button>
                    {selectedImage && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Icon name="status-positive" variant="success" />
                        <div>
                          &nbsp;
                          <span>{selectedImage.name}</span>
                          <div>{(selectedImage.size * 0.000001).toFixed(2)} MB</div>
                        </div>
                      </div>
                    )}
                    <input
                      type="file"
                      ref={inputImageRef}
                      onChange={(event) => setSelectedImage(event?.target?.files![0])}
                      style={{ display: 'none' }}
                    />
                  </FormField>
                  <FormField
                    label="Upload PDF"
                    errorText={uploadUsecasePdfError[0]}
                    description="PDF size should be less than 4MB"
                  >
                    <Button onClick={handlePdfSelect}>
                      <Icon name="upload" />
                      &nbsp;&nbsp;Choose file
                    </Button>
                    {selectedPdf && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Icon name="status-positive" variant="success" />
                        <div>
                          &nbsp;
                          <span>{selectedPdf.name}</span>
                          <div>{(selectedPdf.size * 0.000001).toFixed(2)} MB</div>
                        </div>
                      </div>
                    )}
                    <input
                      type="file"
                      ref={inputPdfRef}
                      accept=".pdf"
                      onChange={(event) => {
                        setSelectedPdf(event?.target?.files![0]);

                        // if (
                        //     event?.target?.files![0]
                        //         .size > 4194304
                        // ) {
                        //     alert('File is too big!');
                        //     event.target.value = '';
                        // }
                      }}
                      style={{ display: 'none' }}
                    />
                  </FormField>
                </>
              )}
            </>
          )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateUsecase;
