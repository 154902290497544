export const COLUMN_DEFINITIONS = [
  {
    id: 'name',
    header: 'Name',
    cell: (item: any) => item?.friendlyName,
    sortingField: 'name',
    // width: 100,
  },
  {
    id: 'id',
    header: 'Use Case ID',
    cell: (item: any) => item?.usecaseId,
    sortingField: 'id',
    // width: 100,
  },
  {
    id: 'url',
    header: 'Home Page URL',
    cell: (item: any) => item?.homepageUrl,
    sortingField: 'url',
    // width: 60,
  },
];
