import { Tabs } from '@cloudscape-design/components';
import ApplicationsTable from 'components/catalog-manager/Applications/ApplicationsTable';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';
import CatalogShopping from '../CatalogShopping';

const CatalogMainTabs = () => {
  const { activeMainTabId, setActiveMainTabId, setStepName } = useCatalogShoppingContext();

  return (
    <Tabs
      tabs={[
        {
          label: 'Applications',
          id: 'applications',
          content: <ApplicationsTable type="shopping" />,
        },
        {
          label: 'Shopping',
          id: 'shopping',
          content: <CatalogShopping />,
        },
      ]}
      className="tabs-container"
      variant="container"
      activeTabId={activeMainTabId}
      onChange={({ detail }) => {
        setActiveMainTabId(detail.activeTabId);
        setStepName('choose');
      }}
    />
  );
};

export default CatalogMainTabs;
