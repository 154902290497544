import SideNavigation from '@cloudscape-design/components/side-navigation';
import { useState } from 'react';
import useLink from 'hooks/useLink';
import { usePageLayoutContext } from '../layout';
import React, { useEffect } from 'react';

const SideNav = () => {
  const { activeSideNav, setActiveSideNav } = usePageLayoutContext();

  const { handleFollow } = useLink();

  useEffect(() => {
    setActiveSideNav(window.location.pathname);
  }, []);

  const handleLink = (event: Readonly<CustomEvent>) => {
    event.preventDefault();
    setActiveSideNav(event.detail.href);
    handleFollow(event);
  };

  return (
    <SideNavigation
      activeHref={activeSideNav}
      onFollow={handleLink}
      header={{
        text: 'Platform Core Management (PCM)',
        href: '',
      }}
      items={[
        {
          type: 'link',
          text: 'Physical Device Manager',
          href: '/lns/list',
        },
        {
          type: 'link',
          text: 'Device Type Manager',
          href: '/device-type/list',
        },
        {
          type: 'link',
          text: 'Device Manager',
          href: '/device/list',
        },
        {
          type: 'link',
          text: 'Catalog Manager',
          href: '/catalog/admin/manager',
        },
        {
          type: 'link',
          text: 'Catalog Shopping',
          href: '/catalog/user/shopping',
        },
        { type: 'divider' },
      ]}
    ></SideNavigation>
  );
};

export default SideNav;
