import React, { createContext, useContext, useEffect, useState } from 'react';
import { ColumnLayout, Box } from '@cloudscape-design/components';

import Spinner from 'components/spinner/Spinner';
import { API_URL_PATH_DEVICE } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { DeviceProps } from 'types/custom';
import DeviceTable from 'components/device-manager/DeviceTable';
import DeviceTabs from 'components/device-manager/DeviceTabs';
import { platformCoreAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { extractFilteringAttributes } from 'utils';

const DeviceContext = createContext({
  allItems: [] as any[],
  loading: false,
  error: '',
  setSelectedDevice: (state: DeviceProps[]) => {},
  selectedDevice: [] as DeviceProps[] | undefined,
  refetch: () => {},
});

export const useDeviceContext = () => useContext(DeviceContext);

const ListDevicesPage = () => {
  const [selectedDevice, setSelectedDevice] = useState<DeviceProps[]>([]);

  const [allItems, setAllItems] = useState<DeviceProps[]>([]);

  const { setBreadcrumb, setNotification } = usePageLayoutContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setBreadcrumb([]);
    if (location?.state?.action) {
      setNotification([
        {
          type: 'success',
          content: location.state.message,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: `flash-${location.state.action}`,
        },
      ]);
    }

    navigate(location.pathname, {});
  }, []);

  const { response, loading, status, error, fetchData } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: API_URL_PATH_DEVICE,
    },
    { manual: false }
  );

  useEffect(() => {
    if (status === 200) {
      setAllItems(extractFilteringAttributes(response.results));
    }
  }, [status, response]);

  useEffect(() => {
    const updatedSelectedItem = allItems.find((device) => device.name === selectedDevice[0].name) 

    if(updatedSelectedItem) {
      setSelectedDevice([updatedSelectedItem])
    }
  }, [allItems])

  return (
    <DeviceContext.Provider
      value={{
        loading,
        allItems,
        error,
        setSelectedDevice,
        selectedDevice,
        refetch: fetchData,
      }}
    >
      <ColumnLayout variant="text-grid">
        {loading && !response && (
          <>
            <br></br>
            <Spinner />
          </>
        )}
        {response && <DeviceTable />}
      </ColumnLayout>

      {response && selectedDevice![0]?.name !== undefined && (
        <>
          <Box margin={{ top: 'xxxl' }}></Box>
          <ColumnLayout variant="text-grid">
            <DeviceTabs />
          </ColumnLayout>
        </>
      )}
    </DeviceContext.Provider>
  );
};

export default ListDevicesPage;
