import { Button } from '@cloudscape-design/components';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';
import CreateApplicationShop from './CreateApplication';
import UsecaseCards from './UsecaseCards';
import UsecaseDetails from './UsecaseDetails';

export const NextButton = ({ handleClick, text }: { handleClick: () => void; text: string }) => {
  return (
    <Button variant="primary" formAction="none" onClick={handleClick}>
      {text}
    </Button>
  );
};

const CatalogShopping = () => {
  const { stepName } = useCatalogShoppingContext();
  return stepName === 'choose' ? (
    <UsecaseCards />
  ) : stepName === 'details' ? (
    <UsecaseDetails />
  ) : (
    <CreateApplicationShop />
  );
};

export default CatalogShopping;
