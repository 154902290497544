import { Container } from '@cloudscape-design/components';
import CreateApplication from 'components/catalog-manager/Applications/CreateApplication';
import CreateUsecase from 'components/catalog-manager/UseCase/CreateUsecase';

const CreateUsecasePage = () => {
  return (
    <Container className="iot-container">
      <CreateUsecase />
    </Container>
  );
};

export default CreateUsecasePage;
