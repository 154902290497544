import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
  Icon,
} from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import DeleteModal from 'components/delete-modal';
import { EmptyState } from 'components/empty-state/EmptyState';
import { API_URL_PATH_LIST_USECASES } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMatchesCountText } from 'utils';
import { COLUMN_DEFINITIONS } from './table.config';

const UseCase = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDeleteDiscard = () => setShowDeleteModal(false);
  const navigate = useNavigate();

  const {
    allUseCases,
    useCaseError,
    useCaseLoading,
    useCaseRefetch: refetchUseCase,
    selectedUseCase,
    setSelectedUseCase,
  } = useCatalogManagerContext();

  const { setNotification } = usePageLayoutContext();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allUseCases || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No usecase"
            subtitle="No usecases to display."
            action={
              <Button
                onClick={() =>
                  navigate('/catalog/admin/usecase/create', {
                    state: {
                      action: 'create',
                    },
                  })
                }
              >
                Create a Usecase
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
    }
  );

  useEffect(() => {
    if (allUseCases) {
      if (allUseCases.length > 0 && allUseCases[0]?.usecaseId) {
        setSelectedUseCase([allUseCases[0]]);
      }
    }
  }, [allUseCases]);

  const {
    status: deleteUsecaseStatus,
    fetchData: deleteUsecase,
    error: deleteUsecaseError,
    loading: deleteUsecaseLoading,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'DELETE',
      url: `${API_URL_PATH_LIST_USECASES}/${selectedUseCase?.length ? selectedUseCase![0]?.usecaseId : ''}`,
    },
    { manual: true }
  );

  useEffect(() => {
    if (deleteUsecaseStatus === 200) {
      refetchUseCase();
      setNotification([
        {
          type: 'success',
          content: `Deleted usecase ${selectedUseCase![0]?.friendlyName} successfully`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-usecase',
        },
      ]);

      if (allUseCases.length > 0 && allUseCases[0]?.usecaseId) {
        setSelectedUseCase([allUseCases[1]]);
      }
    } else if (deleteUsecaseStatus > 200) {
      setNotification([
        {
          type: 'error',
          content: deleteUsecaseError,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-usecase-error',
        },
      ]);
    }
  }, [deleteUsecaseError, deleteUsecaseStatus]);

  const handleButtonDropdownClick = (event: any) => {
    event.preventDefault();
    if (event.detail.id === 'usecase-delete') {
      setShowDeleteModal(true);
    } else if (event.detail.id === 'usecase-update') {
      navigate(`/catalog/admin/usecase/edit/${selectedUseCase![0].usecaseId}`, {
        state: {
          action: 'edit',
          id: selectedUseCase![0].usecaseId,
        },
      });
    }
  };

  const onDeleteConfirm = () => {
    deleteUsecase();
    setShowDeleteModal(false);
  };

  return (
    <>
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => setSelectedUseCase(detail.selectedItems)}
        selectedItems={selectedUseCase}
        loading={useCaseLoading || deleteUsecaseLoading}
        wrapLines={true}
        stripedRows={true}
        resizableColumns={true}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        loadingText="Loading usecases"
        selectionType="single"
        // trackBy='name'
        className="table-container"
        variant="embedded"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find usecase"
          />
        }
        header={
          <Header
            counter={
              selectedUseCase && selectedUseCase?.length && selectedUseCase[0]?.usecaseId
                ? '(' + selectedUseCase.length + `/${allUseCases && allUseCases.length})`
                : `(${allUseCases && allUseCases.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={refetchUseCase} iconName='refresh' loading={useCaseLoading} />
                <Button
                  onClick={() =>
                    navigate('/catalog/admin/usecase/create', {
                      state: {
                        action: 'create',
                      },
                    })
                  }
                >
                  Create
                </Button>
                <ButtonDropdown
                  items={[
                    {
                      text: 'Edit',
                      id: 'usecase-update',
                      disabled: allUseCases?.length === 0,
                    },
                    {
                      text: 'Delete',
                      id: 'usecase-delete',
                      disabled: allUseCases?.length === 0,
                    },
                  ]}
                  onItemClick={handleButtonDropdownClick}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            List of Use Cases
          </Header>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
      />

      {selectedUseCase && selectedUseCase.length > 0 && (
        <DeleteModal
          visible={showDeleteModal}
          onDiscard={onDeleteDiscard}
          onDelete={onDeleteConfirm}
          itemName={selectedUseCase![0]?.friendlyName}
          itemCount={selectedUseCase?.length}
          moduleName="Use Case"
        />
      )}
    </>
  );
};

export default UseCase;
