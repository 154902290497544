import { createContext, useContext, useEffect, useState } from 'react';
import { Box, ColumnLayout } from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import CatalogDetailsTabs from 'components/catalog-manager/CatalogDetailsTabs';
import CatalogMainTabs from 'components/catalog-manager/CatalogMainTabs';
import { API_URL_PATH_LIST_ACCOUNTS, API_URL_PATH_LIST_APPLICATIONS, API_URL_PATH_LIST_USECASES } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { usePageLayoutContext } from 'components/common/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { CatalogAccountProps, CatalogApplicationProps, CatalogUseCaseProps } from 'types/custom';
import Spinner from 'components/spinner/Spinner';

const CatalogManagerContext = createContext({
  allApplications: [] as CatalogApplicationProps[],
  allUseCases: [] as CatalogUseCaseProps[],
  allAccounts: [] as CatalogAccountProps[],
  appsError: '',
  useCaseError: '',
  accountsError: '',
  appsLoading: false,
  useCaseLoading: false,
  accountsLoading: false,
  selectedApplication: [] as CatalogApplicationProps[] | undefined,
  selectedUseCase: [] as CatalogUseCaseProps[] | undefined,
  selectedAccount: [] as CatalogAccountProps[] | undefined,
  setSelectedApplication: (state: CatalogApplicationProps[]) => {},
  setSelectedUseCase: (state: CatalogUseCaseProps[]) => {},
  setSelectedAccount: (state: CatalogAccountProps[]) => {},
  appsRefetch: () => {},
  useCaseRefetch: () => {},
  accountsRefetch: () => {},
  activeMainTabId: '',
  setActiveMainTabId: (state: string) => {},
});

export const useCatalogManagerContext = () => useContext(CatalogManagerContext);

const CatalogManagerPage = () => {
  const [allApplications, setAllApplications] = useState<CatalogApplicationProps[]>([]);
  const [allUseCases, setAllUseCases] = useState<CatalogUseCaseProps[]>([]);
  const [allAccounts, setAllAccounts] = useState<CatalogAccountProps[]>([]);
  const [selectedApplication, setSelectedApplication] = useState<CatalogApplicationProps[]>();
  const [selectedUseCase, setSelectedUseCase] = useState<CatalogUseCaseProps[]>();
  const [selectedAccount, setSelectedAccount] = useState<CatalogAccountProps[]>();
  const [activeMainTabId, setActiveMainTabId] = useState('applications');

  const { setBreadcrumb, setNotification } = usePageLayoutContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setBreadcrumb([]);
    const locationState = location?.state;
    if (locationState?.action) {
      setNotification([
        {
          type: 'success',
          content: locationState.message,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: `flash-${locationState.action}`,
        },
      ]);

      if (locationState.tab) {
        setActiveMainTabId(locationState.tab);
      }
    }

    navigate(location.pathname, {});
  }, []);

  const {
    response: appsResponse,
    loading: appsLoading,
    error: appsError,
    refetch: appsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_APPLICATIONS,
    },
    { manual: false }
  );

  const {
    response: useCaseResponse,
    loading: useCaseLoading,
    error: useCaseError,
    refetch: useCaseRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_USECASES,
    },
    { manual: false }
  );

  const {
    response: accountsResponse,
    loading: accountsLoading,
    error: accountsError,
    refetch: accountsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_ACCOUNTS,
    },
    { manual: false }
  );

  useEffect(() => {
    if (appsResponse) {
      setAllApplications(appsResponse?.items || []);
    }
  }, [appsResponse]);

  useEffect(() => {
    if (useCaseResponse) {
      setAllUseCases(useCaseResponse?.items || []);
    }
  }, [useCaseResponse]);

  useEffect(() => {
    if (accountsResponse) {
      setAllAccounts(accountsResponse?.items || []);
    }
  }, [accountsResponse]);

  return (
    <CatalogManagerContext.Provider
      value={{
        allApplications,
        allUseCases,
        allAccounts,
        appsError,
        useCaseError,
        accountsError,
        appsLoading,
        useCaseLoading,
        accountsLoading,
        selectedApplication,
        selectedUseCase,
        selectedAccount,
        setSelectedApplication,
        setSelectedUseCase,
        setSelectedAccount,
        appsRefetch,
        useCaseRefetch,
        accountsRefetch,
        activeMainTabId,
        setActiveMainTabId,
      }}
    >
      <ColumnLayout variant="text-grid">
        {appsLoading && !selectedApplication && (
          <>
            <br></br>
            <Spinner />
          </>
        )}
        {appsResponse && <CatalogMainTabs />}
      </ColumnLayout>

      {appsResponse && (
        <>
          <Box margin={{ top: 'xxxl' }}></Box>
          <ColumnLayout variant="text-grid">
            <CatalogDetailsTabs />
          </ColumnLayout>
        </>
      )}
    </CatalogManagerContext.Provider>
  );
};

export default CatalogManagerPage;
