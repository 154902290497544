/* eslint-disable react-hooks/rules-of-hooks */
import { SpaceBetween, Box, ColumnLayout } from '@cloudscape-design/components';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';

const ApplicationDetails = ({ type }: { type: string }) => {
  const context = type === 'manager' ? useCatalogManagerContext() : useCatalogShoppingContext();

  const { selectedApplication } = context;

  const appOutputs =
    selectedApplication &&
    selectedApplication[0]?.outputs &&
    Object.entries(selectedApplication![0]?.outputs).map(([key, value]) => {
      return (
        <div key={key}>
          <Box variant="awsui-key-label">{key}</Box>
          <div>{value}</div>
        </div>
      );
    });

  const appParameters =
    selectedApplication &&
    selectedApplication[0]?.parameters &&
    Object.entries(selectedApplication![0]?.parameters).map(([key, value]) => {
      return (
        <div key={key}>
          <Box variant="awsui-key-label">{key}</Box>
          <div>{value}</div>
        </div>
      );
    });

  return (
    <ColumnLayout columns={3} variant="text-grid">
      {selectedApplication ? (
        <>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Friendly Name</Box>
              <div>{selectedApplication![0]?.friendlyName}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Description</Box>
              <div>{selectedApplication![0]?.description}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Usecase ID</Box>
              <div>{selectedApplication![0]?.usecaseId}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Updated At</Box>
              <div>{selectedApplication![0]?.updatedAt}</div>
            </div>

            <div>
              <Box variant="awsui-key-label">AWS Account ID</Box>
              <div>{selectedApplication![0]?.accountId}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Region</Box>
              <div>{selectedApplication![0]?.region}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Username</Box>
              <div>{selectedApplication![0]?.userName}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="l">{appOutputs}</SpaceBetween>
          <SpaceBetween size="l">{appParameters}</SpaceBetween>
        </>
      ) : (
        <i>No data to display</i>
      )}
    </ColumnLayout>
  );
};

export default ApplicationDetails;
