import { Tabs } from '@cloudscape-design/components';
import Tags from 'components/tags';
import DataModel from '../DataModel';
import DeviceTypeDetails from '../DeviceTypeDetails';

const DeviceTypesTabs = () => {
  return (
    <Tabs
      tabs={[
        {
          label: 'Details',
          id: 'details',
          content: <DeviceTypeDetails />,
        },
        {
          label: 'Data Model',
          id: 'data-model',
          content: <DataModel />,
        },
        // {
        //     label: 'Tags',
        //     id: 'tags',
        //     content: <Tags />,
        // },
      ]}
      className="tabs-container"
      variant="container"
    />
  );
};

export default DeviceTypesTabs;
