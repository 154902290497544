import {
  SpaceBetween,
  Button,
  Header,
  FormField,
  Input,
  Form,
  Select,
  ColumnLayout,
  SelectProps,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { catalogAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import Spinner from 'components/spinner/Spinner';
import {
  API_URL_PATH_LIST_APPLICATIONS,
  API_URL_PATH_LIST_CATALOG_ACCOUNTS,
  API_URL_PATH_LIST_SHOPPING_ACCOUNTS,
  API_URL_PATH_LIST_SHOPPING_APPLICATIONS,
  API_URL_PATH_LIST_SHOPPING_USECASES,
  API_URL_PATH_LIST_USECASES,
  URL_PATH_CATALOG_MANAGER,
  URL_PATH_CATALOG_SHOPPING,
  URL_PATH_CREATE_CATALOG_APPLICATION,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectAccountsProps, selectUseCaseProps } from 'utils';
import { REGION_OPTIONS } from '../../Common';

const CreateApplication = () => {
  const [selectedUsecase, setSelectedUsecase] = useState<any>('');
  const [friendlyName, setFriendlyName] = useState('');
  const [description, setDescription] = useState('');
  const [iotApplicationId, setIotApplicationId] = useState('');
  const [region, setRegion] = useState<OptionDefinition | null>(null);
  const [parameters, setParameters] = useState<any>({});
  const [snowParentsCi, setSnowParentsCi] = useState('');
  const [disableFormFields, setDisableFormFields] = useState(false);

  const [usecaseList, setUsecaseList] = useState([]);
  const [usecaseItems, setUsecaseItems] = useState([]);

  const [regAccountsList, setRegAccountsList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState<any>('');

  const [requiredParameters, setRequiredParameters] = useState<string[]>([]);

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [flowType, setFlowType] = useState('manager');

  const [regionOptions, setRegionOptions] = useState<SelectProps.Options | undefined>(undefined);

  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();

  const { setBreadcrumb } = usePageLayoutContext();

  useEffect(() => {
    let breadcrumbItems = [];
    // setFlowType(location.state?.flowType);
    setFlowType('manager');

    if (
      (location && location.state?.action === 'edit') ||
      (location.pathname && location.pathname.indexOf('edit') > -1)
    ) {
      breadcrumbItems.push({
        text: 'Edit',
        href: '',
      });

      setIsEditFlow(true);
    } else {
      breadcrumbItems.push({
        text: 'Create',
        href: '/catalog/admin/application/create',
      });
    }

    const appUrl = flowType === 'manager' ? '/catalog/admin/manager' : '/catalog/user/shopping';

    setBreadcrumb([
      {
        text: 'Applications',
        href: appUrl,
      },
      ...breadcrumbItems,
    ]);
  }, []);

  const {
    loading: applicationLoading,
    error: applicationError,
    fetchData: getApplication,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: flowType === 'manager' ? API_URL_PATH_LIST_APPLICATIONS : API_URL_PATH_LIST_SHOPPING_APPLICATIONS,
      param: params?.iotApplicationId,
    },
    { manual: true }
  );

  const {
    loading: accountsLoading,
    error: accountsError,
    response: accountsResponse,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: flowType === 'manager' ? API_URL_PATH_LIST_CATALOG_ACCOUNTS : API_URL_PATH_LIST_SHOPPING_ACCOUNTS,
    },
    { manual: false }
  );

  useEffect(() => {
    if (accountsResponse) {
      const accountsOptions = accountsResponse?.items?.map(selectAccountsProps);
      setRegAccountsList(accountsOptions);
    }
  }, [accountsResponse]);

  useEffect(() => {
    if (isEditFlow) {
      getApplication()
        .then((application) => {
          if (application?.data) {
            setIotApplicationId(application.data.iotApplicationId);
            setFriendlyName(application.data.friendlyName);
            setDescription(application.data.description);
            setSnowParentsCi(application.data.snowParentsCi);
          } else {
            const navUrl =
              flowType === 'manager' ? URL_PATH_CREATE_CATALOG_APPLICATION : API_URL_PATH_LIST_SHOPPING_APPLICATIONS;
            navigate(navUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isEditFlow]);

  const {
    response: usecaseListResponse,
    loading: usecaseLoading,
    error: usecaseError,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: flowType === 'manager' ? API_URL_PATH_LIST_USECASES : API_URL_PATH_LIST_SHOPPING_USECASES,
    },
    { manual: false }
  );

  useEffect(() => {
    if (usecaseListResponse) {
      setUsecaseItems(usecaseListResponse?.items);

      const usecaseOptions = usecaseListResponse?.items?.map(selectUseCaseProps);
      setUsecaseList(usecaseOptions);
    }
  }, [usecaseListResponse]);

  const {
    error: updateAppError,
    loading: updateAppLoading,
    status: updateAppStatus,
    fetchData: updateApp,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'PATCH',
      url: `${
        flowType === 'manager' ? API_URL_PATH_LIST_APPLICATIONS : API_URL_PATH_LIST_SHOPPING_APPLICATIONS
      }/${iotApplicationId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        friendlyName,
        description,
        snowParentsCi,
      },
    },
    { manual: true }
  );

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_LIST_APPLICATIONS,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        usecaseId: selectedUsecase?.value,
        friendlyName,
        description,
        iotApplicationId,
        region: region?.value,
        parameters,
        snowParentsCi,
        accountId: selectedAccount.value,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (status === 201 || updateAppStatus === 200) {
      let stateObj = {};

      if (isEditFlow) {
        stateObj = {
          action: 'update-application',
          status: 'success',
          message: `Successfully updated application ${iotApplicationId}`,
          tab: 'applications',
        };
      } else {
        stateObj = {
          action: 'create-application',
          status: 'success',
          message: `Successfully created application ${iotApplicationId}`,
          tab: 'applications',
        };
      }

      const navUrl = flowType === 'manager' ? URL_PATH_CATALOG_MANAGER : URL_PATH_CATALOG_SHOPPING;

      navigate(navUrl, {
        state: stateObj,
      });
    } else if (status > 201) {
      setDisableFormFields(false);
    }
  }, [status, updateAppStatus]);

  const updateParameterFields = (usecase: OptionDefinition) => {
    usecaseItems?.map((item: any) => {
      if (item.usecaseId === usecase?.value) {
        setRequiredParameters(item.requiredParameters);
      }
    });
  };

  const handleRequiredParameters = (paramValue: string, param: string) => {
    let paramObj = { ...parameters };
    paramObj[param] = paramValue;
    setParameters(paramObj);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);

    if (isEditFlow) {
      updateApp();
    } else {
      fetchData();
    }
  };

  useEffect(() => {
    if (selectedUsecase) {
      usecaseItems.map((caseItem: any) => {
        if (selectedUsecase.value === caseItem.usecaseId) {
          const selectRegions = caseItem.regionAvailability.map((region: string) => {
            return { label: region, value: region };
          });

          setRegionOptions(selectRegions);
        }
      });
    }
  }, [selectedUsecase]);

  return (
    <form onSubmit={handleSubmit}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate(URL_PATH_CATALOG_MANAGER, {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={loading || updateAppLoading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            {isEditFlow ? 'Update' : 'Create'} an IoT Application
          </Header>
        }
        errorText={error || applicationError || updateAppError}
      >
        <SpaceBetween direction="vertical" size="l">
          {applicationLoading ? (
            <Spinner />
          ) : (
            <>
              <FormField label="IoT Application ID">
                <Input
                  disabled={disableFormFields || isEditFlow}
                  value={iotApplicationId}
                  onChange={(event) => setIotApplicationId(event.detail.value)}
                />
              </FormField>
              <FormField label="Friendly name">
                <Input
                  disabled={disableFormFields}
                  value={friendlyName}
                  onChange={(event) => setFriendlyName(event.detail.value)}
                />
              </FormField>
              <FormField label="Description">
                <Input
                  disabled={disableFormFields}
                  value={description}
                  onChange={(event) => setDescription(event.detail.value)}
                />
              </FormField>

              <FormField label="AWS Account ID">
                <Select
                  selectedOption={selectedAccount}
                  onChange={({ detail }) => {
                    setSelectedAccount(detail.selectedOption);
                  }}
                  options={regAccountsList}
                  loadingText="Loading accounts"
                  placeholder="Choose an account"
                  statusType={accountsLoading ? 'loading' : 'finished'}
                  errorText={accountsError}
                  disabled={disableFormFields}
                  empty="No registered accounts"
                />
              </FormField>

              <FormField label="SNOW Parent CI">
                <Input
                  disabled={disableFormFields}
                  value={snowParentsCi}
                  onChange={(event) => setSnowParentsCi(event.detail.value)}
                />
              </FormField>
            </>
          )}
          {!isEditFlow && (
            <>
              <FormField label="Usecase ID">
                <Select
                  selectedOption={selectedUsecase}
                  onChange={({ detail }) => {
                    setSelectedUsecase(detail.selectedOption);
                    updateParameterFields(detail.selectedOption);
                  }}
                  options={usecaseList}
                  loadingText="Loading instances"
                  placeholder="Choose a usecase"
                  statusType={usecaseLoading ? 'loading' : 'finished'}
                  errorText={usecaseError}
                  disabled={disableFormFields}
                  empty="No usecases"
                />
              </FormField>

              {requiredParameters.length > 0 && (
                <FormField label="Parameters" description="Please input the values for each parameter">
                  <ColumnLayout columns={requiredParameters?.length > 2 ? 3 : 2}>
                    {requiredParameters.map((param) => (
                      <div key={param}>
                        <span>{param}</span>
                        <Input
                          disabled={disableFormFields}
                          value={parameters[param]}
                          onChange={(event) => {
                            handleRequiredParameters(event.detail.value, param);
                          }}
                        />
                      </div>
                    ))}
                  </ColumnLayout>
                </FormField>
              )}

              <FormField label="Region">
                <Select
                  selectedOption={region}
                  onChange={({ detail }) => {
                    setRegion(detail.selectedOption);
                  }}
                  options={regionOptions}
                  placeholder="Choose a region"
                  disabled={disableFormFields}
                  empty="No regions"
                />
              </FormField>
            </>
          )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateApplication;
