import { Box, Cards } from '@cloudscape-design/components';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';
import { useEffect } from 'react';
import { NextButton } from '..';

const UsecaseCards = () => {
  const { allUseCases, setSelectedUseCase, selectedUseCase, useCaseLoading, setStepName } = useCatalogShoppingContext();
  const defaultUsecaseImage = 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg';

  useEffect(() => {
    setSelectedUseCase(allUseCases);
  }, []);

  const handleNextStep = () => {
    return setStepName('details');
  };

  return (
    <>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: '20px' }}>1. Choose a Usecase</div>
        <NextButton handleClick={handleNextStep} text="Next" />
      </div>
      <br />
      <Cards
        className="cards-container"
        ariaLabels={{
          itemSelectionLabel: (e, n) => `select ${n.friendlyName}`,
          selectionGroupLabel: 'Item selection',
        }}
        cardDefinition={{
          header: (item: any) => <h4>{item.friendlyName}</h4>,
          sections: [
            {
              id: 'image',
              content: (item) => (
                <img
                  src={item.imgUrl || defaultUsecaseImage}
                  alt="catalog usecase"
                  className="card-section-image"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = defaultUsecaseImage;
                  }}
                  style={{
                    height: '200px',
                    width: '100%',
                  }}
                />
              ),
            },
            {
              id: 'description',
              header: 'Description',
              content: (item) => item.description,
            },
            {
              id: 'contact',
              header: 'Contact Info',
              content: (item) => item.contactInfo,
            },
            {
              id: 'homepage-url',
              header: 'Homepage URL',
              content: (item) => item.homepageUrl,
            },
          ],
        }}
        cardsPerRow={[{ cards: 1 }, { minWidth: 200, cards: 3 }]}
        items={allUseCases}
        loadingText="Loading resources"
        selectionType="single"
        onSelectionChange={(event) => setSelectedUseCase(event.detail.selectedItems)}
        selectedItems={selectedUseCase}
        variant="container"
        visibleSections={['image', 'description', 'contact', 'homepage-url']}
        trackBy="friendlyName"
        loading={useCaseLoading}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
              No resources to display.
            </Box>
          </Box>
        }
      />
      <br />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <NextButton handleClick={handleNextStep} text="Next" />
      </div>
      <br />
    </>
  );
};

export default UsecaseCards;
